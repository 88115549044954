import { graphql } from 'gatsby'
import defineHtmlAst from 'helpers/defineHtmlAst'
import { ISalesMasteryPageProps } from 'interfaces/PageInterfaces'
import React from 'react'
import { Helmet } from 'react-helmet'
import {
  AboutCourseTurkishSection,
  BannerSection,
  CourseNavigation,
  ModulesSection,
  WhoThisIsForTurkishSection,
  ScheduleTurkishSection,
  MeetInstructorAlperSection,
  FAQSection,
  ConnectTurkishSection,
  PartnersTurkishSection,
  EmbeddedVideosSection,
  SocialLearningSection,
} from 'sections/SalesMastery'

const SalesMastery = ({ data }: ISalesMasteryPageProps) => {
  const {
    bannerText,
    navItems,
    courseOverview,
    whoThisIsFor,
    scheduleTexts,
    meetYourInstructor,
    faq,
    faqTitle,
    partners,
    modulesTitle,
    socialLearning,
    whatWeHaveToSay,
    whatWeHaveToSayTitle,
    whatOurClientsSay,
    whatOurClientsSayTitle,
    modulesData,
  } = data

  const navTabs = defineHtmlAst(navItems.items)

  const aboutCourseArgs = {
    aboutTitle: courseOverview.aboutTitle,
    aboutDescription:
      courseOverview.aboutDescription.description,
    whatIsIncludedTitle: courseOverview.whatIsIncludedTitle,
    whatIsIncludedList: defineHtmlAst(
      courseOverview.whatIsIncludedList,
    ),
    whatYouWillLearn: courseOverview.whatYouWillLearnTitle,
    whatYouWillLearnList: defineHtmlAst(
      courseOverview.whatYouWillLearnList,
    ),
  }
  const whoThisIsForArgs = {
    title: whoThisIsFor.title,
    articles: defineHtmlAst(whoThisIsFor.list),
  }

  const meetYourInstructorArgs = {
    title: meetYourInstructor.title,
    description: meetYourInstructor.description.description,
  }

  const socialLearningArgs = {
    title: socialLearning.title,
    description: socialLearning.description.description,
    sectionImage: socialLearning.sectionImage.gatsbyImage,
  }

  const whatWeHaveToSayArgs = {
    title: whatWeHaveToSayTitle.header,
    embeddedVideos: whatWeHaveToSay.nodes,
    breakPoints: {
      840: {
        slidesPerView: 2,
      },
    },
  }

  const whatOurClientsSayArgs = {
    title: whatOurClientsSayTitle.header,
    embeddedVideos: whatOurClientsSay.nodes,
    indexAsString: '1',
  }

  const modules = modulesData.nodes.sort(
    (a, b) => a.index - b.index,
  )

  return (
    <>
      <Helmet title="Sales Mastery" defer={false} />
      <BannerSection {...bannerText} />
      {/* <StartCourseSection /> */}
      <CourseNavigation tabs={navTabs} />
      <AboutCourseTurkishSection {...aboutCourseArgs} />
      <EmbeddedVideosSection {...whatWeHaveToSayArgs} />
      <EmbeddedVideosSection {...whatOurClientsSayArgs} />
      <WhoThisIsForTurkishSection {...whoThisIsForArgs} />
      <ModulesSection
        modules={modules}
        modulesTitle={modulesTitle.header}
      />
      <ScheduleTurkishSection {...scheduleTexts} />
      <PartnersTurkishSection partners={partners.nodes} />
      <MeetInstructorAlperSection
        {...meetYourInstructorArgs}
      />
      <SocialLearningSection {...socialLearningArgs} />
      <FAQSection
        questionsData={faq.nodes}
        title={faqTitle.header}
      />
      <ConnectTurkishSection />
    </>
  )
}

export default SalesMastery

export const SalesMasteryPageData = graphql`
  query {
    bannerText: contentfulSalesMasteryPageTurkish {
      header: block1BannerHeader
      subHeader: block1BannerSubHeader
    }
    navItems: contentfulSalesMasteryPageTurkish {
      items: block2NavigationList {
        childrenMarkdownRemark {
          htmlAst
        }
      }
    }
    partners: allContentfulPartnerCompanies {
      nodes {
        logo {
          gatsbyImageData(width: 100, quality: 80)
        }
      }
    }
    courseOverview: contentfulSalesMasteryPageTurkish {
      aboutTitle: block3AboutTitle
      aboutDescription: block3AboutDescription {
        description: block3AboutDescription
      }
      whatIsIncludedTitle: block3WhatIsIncludedTitle
      whatIsIncludedList: block3WhatIsIncludedList {
        childrenMarkdownRemark {
          htmlAst
        }
      }
      whatYouWillLearnTitle: block3WhatYouWillLearnTitle
      whatYouWillLearnList: block3WhatYouWillLearnList {
        childrenMarkdownRemark {
          htmlAst
        }
      }
    }
    whoThisIsFor: contentfulSalesMasteryPageTurkish {
      title: block4WhoThisIsForTitle
      list: block4WhoThisIsForList {
        childrenMarkdownRemark {
          htmlAst
        }
      }
    }
    scheduleTexts: contentfulSalesMasteryPageTurkish {
      title: block5InterestedTitle
      description: block5InterestedDescription
    }
    meetYourInstructor: contentfulSalesMasteryPageTurkish {
      title: block6MeetInstructorTitle
      description: block6MeetInstructorDescription {
        description: block6MeetInstructorDescription
      }
    }
    socialLearning: contentfulSalesMasteryPageTurkish {
      title: block7SocialLearningTitle
      description: block7SocialLearningDescription {
        description: block7SocialLearningDescription
      }
      sectionImage: block7SocialLearningImage {
        gatsbyImage(
          width: 450
          quality: 85
          placeholder: BLURRED
        )
      }
    }
    whatWeHaveToSayTitle: contentfulSalesMasteryPageTurkish {
      header: block3WhatWeHaveToSayTitle
    }
    whatWeHaveToSay: allContentfulSalesMasteryWhatWeHaveToSayTurkish {
      nodes {
        embeddedVideo {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    whatOurClientsSayTitle: contentfulSalesMasteryPageTurkish {
      header: block3WhatOurClientsSayTitle
    }
    whatOurClientsSay: allContentfulSalesMasteryWhatOurClientsSayTurkish {
      nodes {
        embeddedVideo {
          childMarkdownRemark {
            html
          }
        }
      }
    }
    faq: allContentfulSalesMasteryFaqQuestionTurkish {
      nodes {
        question
        answer {
          answer
        }
      }
    }
    faqTitle: contentfulSalesMasteryPageTurkish {
      header: block7FaqTitle
    }
    modulesTitle: contentfulSalesMasteryPageTurkish {
      header: block5ModulesTitle
    }
    modulesData: allContentfulSalesMasteryModuleTurkish {
      nodes {
        index: moduleIndex
        title: moduleTitle
        description: moduleDescription
        moduleImage {
          gatsbyImage(
            width: 450
            quality: 85
            placeholder: BLURRED
          )
        }
        fullDataTitle: moduleFullDataTitle
        fullDataLabel: moduleFullDataLabel
        fullDataDescription: moduleFullDataDescription {
          description: moduleFullDataDescription
        }
      }
    }
    # whoWeHelpedTestimonials: allContentfulSalesMasteryTestemonialTurkish {
    #   nodes {
    #     fullName
    #     designation
    #     review {
    #       review
    #     }
    #     profilePicture {
    #       gatsbyImage(width: 450, quality: 85)
    #     }
    #     embeddedVideo {
    #       childMarkdownRemark {
    #         html
    #       }
    #     }
    #   }
    # }
  }
`
